import React from 'react';
import clsx from 'clsx';

import styles from './PageLayout.module.css';

import { Breadcrumbs } from '../../../features';

export function PageLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={'flex flex-col w-full mb-48'}>
      <Breadcrumbs />
      <div className={clsx(styles.content, className)}>{children}</div>
    </div>
  );
}
