import React, { useEffect } from 'react';

import { PageLayout } from '../../../ui/layouts/PageLayout';
import {
  Header,
  Card,
  CardRow,
  Select,
  SelectItem,
  Switch,
  SlackLogo,
} from '../../../ui';
import {
  useRequest,
  useRouter,
  useProjectContext,
  useAccountContext,
} from '../../../hooks';

export function ProjectSettings() {
  const { request, data: channels } = useRequest();
  const { request: updateSettingsRequest } = useRequest();

  const ROUTER = useRouter();
  const { project, refetchProject } = useProjectContext();
  const account = useAccountContext();

  useEffect(() => {
    request({
      url: `/api/v1/apps/slack/channels-list?project_unique_id=${ROUTER.PARAMS.PROJECT_UNIQUE_ID}`,
    });
  }, []);

  return (
    <PageLayout>
      <Header>
        <h1>Project Settings</h1>
      </Header>
      {account?.default_organization?.integrations?.slack_connected && (
        <Card>
          <CardRow className='flex justify-between items-center'>
            <div className='flex items-center'>
              <SlackLogo height={'28'} width={'28'} className='mr-2' />{' '}
              <strong>Slack</strong>
            </div>
            {account?.permissions?.can_manage_settings && (
              <Switch
                checked={project?.slack_settings?.on}
                id={'slackOn'}
                onCheckedChange={() => {
                  updateSettingsRequest({
                    url: `/api/v1/projects/${ROUTER.PARAMS.PROJECT_UNIQUE_ID}/project-settings/slack`,
                    method: 'put',
                    data: {
                      settings_unique_id: project?.slack_settings?.unique_id,
                      on: !project?.slack_settings?.on,
                    },
                    onSuccess: refetchProject,
                  });
                }}
              />
            )}
          </CardRow>
          <CardRow className='flex justify-between items-center'>
            <div>
              {account?.permissions?.can_manage_settings ? (
                <>
                  <p>
                    <strong>Channel</strong>
                  </p>
                  <p>Choose the channel you notifications to appear in.</p>
                </>
              ) : (
                <>
                  <p>Please contact an admin to update these settings.</p>
                </>
              )}
            </div>
            <div>
              {account?.permissions?.can_manage_settings && (
                <Select
                  placeholder='Select a channel'
                  disabled={!project?.slack_settings?.on}
                  defaultValue={project?.slack_settings?.channel_id}
                  onChange={(value) => {
                    updateSettingsRequest({
                      url: `/api/v1/projects/${ROUTER.PARAMS.PROJECT_UNIQUE_ID}/project-settings/slack`,
                      method: 'put',
                      data: {
                        settings_unique_id: project?.slack_settings?.unique_id,
                        channel_name: channels?.filter(
                          (channel) => channel.id === value
                        )[0].name,
                        channel_id: value,
                      },
                      onSuccess: refetchProject,
                    });
                  }}
                >
                  {channels?.map((channel) => {
                    return (
                      <SelectItem key={channel.id} value={channel.id}>
                        {channel.name}
                      </SelectItem>
                    );
                  })}
                </Select>
              )}
            </div>
          </CardRow>
        </Card>
      )}
    </PageLayout>
  );
}
