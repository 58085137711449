import React from 'react';

import { ProjectContext } from '../contexts/project';

export function useProjectContext() {
  const project = React.useContext(ProjectContext);

  if (project === undefined) {
    throw new Error('useProjectContext must be used within an ProjectContext');
  }

  return project;
}
