import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useRouter } from '../../../hooks';
import { AccountContext } from '../../../contexts/account';

import { SuperSweetLogo } from '../../../ui/logos/SuperSweet';

import styles from './Nav.module.css';

export function Nav() {
  const ROUTER = useRouter();
  const account = useContext(AccountContext);

  return (
    <nav className={clsx(styles.nav, 'flex justify-between')}>
      <div className='flex-col items-center justify-center'>
        <a
          href='/'
          className={clsx(
            styles['logo-link'],
            'flex items-center justify-center'
          )}
        >
          <SuperSweetLogo className={clsx(styles.logo)} />
        </a>

        <Link
          to={`/${ROUTER.PARAMS.ORGANIZATION_SLUG}/settings/account`}
          className={clsx(styles.link, 'flex items-center justify-center')}
        >
          <div
            style={{
              backgroundImage: `url(${account?.avatar_url})`,
            }}
            className={clsx(styles.avatar)}
          />
        </Link>

        <ul>
          <li>
            <Link to={`/${ROUTER.PARAMS.ORGANIZATION_SLUG}/projects`}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2 9.4C2 7.15979 2 6.03968 2.43597 5.18404C2.81947 4.43139 3.43139 3.81947 4.18404 3.43597C5.03968 3 6.15979 3 8.4 3H8.71556C9.18517 3 9.41997 3 9.6331 3.06473C9.82179 3.12203 9.99732 3.21597 10.1497 3.34118C10.3217 3.48262 10.452 3.67798 10.7125 4.06872L11.2875 4.93128C11.548 5.32202 11.6783 5.51739 11.8503 5.65882C12.0027 5.78403 12.1782 5.87797 12.3669 5.93527C12.58 6 12.8148 6 13.2844 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C22 9.03969 22 10.1598 22 12.4V14.6C22 16.8402 22 17.9603 21.564 18.816C21.1805 19.5686 20.5686 20.1805 19.816 20.564C18.9603 21 17.8402 21 15.6 21H8.4C6.15979 21 5.03968 21 4.18404 20.564C3.43139 20.1805 2.81947 19.5686 2.43597 18.816C2 17.9603 2 16.8402 2 14.6V9.4Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Projects
            </Link>
          </li>

          <li>
            <Link to={ROUTER.PATH.ACTIVITY}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2 12H5.27924C5.70967 12 6.09181 11.7246 6.22792 11.3162L9 3L15 21L17.7721 12.6838C17.9082 12.2754 18.2903 12 18.7208 12H22'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Activity
            </Link>
          </li>

          <li>
            <Link to={`/${ROUTER.PARAMS.ORGANIZATION_SLUG}/settings`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                fill='none'
                viewBox='0 0 24 24'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M10.398 4.28c.387-1.706 2.817-1.706 3.204 0l.051.225a1.642 1.642 0 0 0 2.478 1.027l.196-.124c1.48-.933 3.198.786 2.265 2.265l-.123.196a1.642 1.642 0 0 0 1.026 2.478l.226.051c1.705.387 1.705 2.817 0 3.204l-.226.051a1.642 1.642 0 0 0-1.027 2.478l.124.196c.933 1.48-.786 3.198-2.265 2.265l-.196-.123a1.642 1.642 0 0 0-2.478 1.026l-.051.226c-.387 1.705-2.817 1.705-3.204 0l-.051-.226a1.642 1.642 0 0 0-2.478-1.027l-.196.124c-1.48.933-3.198-.786-2.265-2.265l.124-.196a1.642 1.642 0 0 0-1.027-2.478l-.226-.051c-1.705-.387-1.705-2.817 0-3.204l.226-.051a1.643 1.643 0 0 0 1.027-2.478l-.124-.196c-.933-1.48.786-3.198 2.265-2.265l.196.124a1.643 1.643 0 0 0 2.478-1.027l.051-.226Z'
                />
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M10.99 12c0-.552.458-1 1.01-1s1.01.448 1.01 1-.458 1-1.01 1-1.01-.448-1.01-1Z'
                />
              </svg>
              Settings
            </Link>
          </li>
          <li>
            <a href={`/logout`}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.1885 9C19.1755 9.74024 20.0668 10.599 20.8426 11.5564C20.9475 11.6859 21 11.843 21 12M21 12C21 12.157 20.9475 12.3141 20.8426 12.4436C20.0668 13.401 19.1755 14.2598 18.1885 15M21 12H8M13 4.52779C11.9385 3.57771 10.5367 3 9 3C5.68629 3 3 5.68629 3 9V15C3 18.3137 5.68629 21 9 21C10.5367 21 11.9385 20.4223 13 19.4722'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Log out
            </a>
          </li>
        </ul>
      </div>
      <ul>
        <li>
          <Link to={'/help'}>
            <svg
              width='20'
              height='22'
              viewBox='0 0 20 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.9 10.4444V13.2222M4.9 10.4444V3.77778C4.9 2.8573 5.66112 2.11111 6.6 2.11111C7.53888 2.11111 8.3 2.8573 8.3 3.77778M4.9 10.4444C4.9 9.52397 4.13888 8.77778 3.2 8.77778C2.26112 8.77778 1.5 9.52397 1.5 10.4444V12.6667C1.5 17.269 5.30558 21 10 21C14.6944 21 18.5 17.269 18.5 12.6667V7.11111C18.5 6.19064 17.7389 5.44444 16.8 5.44444C15.8611 5.44444 15.1 6.19064 15.1 7.11111M8.3 3.77778V9.88889M8.3 3.77778V2.66667C8.3 1.74619 9.06112 1 10 1C10.9389 1 11.7 1.74619 11.7 2.66667V3.77778M11.7 3.77778V9.88889M11.7 3.77778C11.7 2.8573 12.4611 2.11111 13.4 2.11111C14.3389 2.11111 15.1 2.8573 15.1 3.77778V7.11111M15.1 7.11111V9.88889'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Help
          </Link>
        </li>
      </ul>
    </nav>
  );
}
