import React from 'react';

import styles from './Loader.module.css';

export function Loader() {
  return (
    <div className='flex justify-center w-full mt-8'>
      <span className={styles.loader}></span>
    </div>
  );
}
