import React from 'react';

export function SlackLogo({ className, height = '57', width = '56' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 56 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M11.7478 36.0564C11.7478 39.2926 9.13225 41.9081 5.89606 41.9081C2.65988 41.9081 0.0443344 39.2926 0.0443344 36.0564C0.0443344 32.8202 2.65988 30.2047 5.89606 30.2047H11.7478V36.0564ZM14.6737 36.0564C14.6737 32.8202 17.2892 30.2047 20.5254 30.2047C23.7616 30.2047 26.3771 32.8202 26.3771 36.0564V50.6857C26.3771 53.9219 23.7616 56.5374 20.5254 56.5374C17.2892 56.5374 14.6737 53.9219 14.6737 50.6857V36.0564Z'
        fill='#E01E5A'
      />
      <path
        d='M20.5254 12.5608C17.2892 12.5608 14.6737 9.94526 14.6737 6.70907C14.6737 3.47289 17.2892 0.857346 20.5254 0.857346C23.7616 0.857346 26.3771 3.47289 26.3771 6.70907V12.5608H20.5254ZM20.5254 15.531C23.7616 15.531 26.3771 18.1465 26.3771 21.3827C26.3771 24.6189 23.7616 27.2345 20.5254 27.2345H5.85173C2.61555 27.2345 0 24.6189 0 21.3827C0 18.1465 2.61555 15.531 5.85173 15.531H20.5254Z'
        fill='#36C5F0'
      />
      <path
        d='M43.9766 21.3827C43.9766 18.1465 46.5922 15.531 49.8284 15.531C53.0645 15.531 55.6801 18.1465 55.6801 21.3827C55.6801 24.6189 53.0645 27.2345 49.8284 27.2345H43.9766V21.3827ZM41.0508 21.3827C41.0508 24.6189 38.4352 27.2345 35.199 27.2345C31.9629 27.2345 29.3473 24.6189 29.3473 21.3827V6.70907C29.3473 3.47289 31.9629 0.857346 35.199 0.857346C38.4352 0.857346 41.0508 3.47289 41.0508 6.70907V21.3827Z'
        fill='#2EB67D'
      />
      <path
        d='M35.199 44.834C38.4352 44.834 41.0508 47.4495 41.0508 50.6857C41.0508 53.9219 38.4352 56.5374 35.199 56.5374C31.9629 56.5374 29.3473 53.9219 29.3473 50.6857V44.834H35.199ZM35.199 41.9081C31.9629 41.9081 29.3473 39.2926 29.3473 36.0564C29.3473 32.8202 31.9629 30.2047 35.199 30.2047H49.8727C53.1089 30.2047 55.7244 32.8202 55.7244 36.0564C55.7244 39.2926 53.1089 41.9081 49.8727 41.9081H35.199Z'
        fill='#ECB22E'
      />
    </svg>
  );
}
