import React, { useEffect } from 'react';
import clsx from 'clsx';
import { PageLayout, Card, Loader, Button } from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';

import styles from './OauthSlack.module.css';

export function OauthSlack() {
  const { request, data, error, isLoading } = useRequest();
  const ROUTER = useRouter();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const organizationSlug = urlParams.get('state');

  if (!isLoading && !error && data) {
    setTimeout(() => {
      ROUTER.NAVIGATE(`/${organizationSlug}/settings/apps`);
    }, 4000);
  }

  const getSlackRedirectUri = () => {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('ngrok')
    ) {
      return ROUTER.REDIRECT_URIS.SLACK.OAUTH.DEVELOPMENT;
    }

    if (window.location.href.includes('herokuapp')) {
      return ROUTER.REDIRECT_URIS.SLACK.OAUTH.STAGING;
    }

    if (window.location.href.includes('supersweetness.com')) {
      return ROUTER.REDIRECT_URIS.SLACK.OAUTH.PRODUCTION;
    }
  };

  useEffect(() => {
    request({
      url: `/api/v1/oauth/slack?code=${code}&organization_slug=${organizationSlug}&redirect_uri=${getSlackRedirectUri()}`,
    });
  }, []);

  return (
    <PageLayout className={styles.page}>
      <Card className={styles.card}>
        <div className={clsx(styles.header, 'mb-8')}>
          <svg
            width='56'
            height='57'
            viewBox='0 0 56 57'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.7478 36.0564C11.7478 39.2926 9.13225 41.9081 5.89606 41.9081C2.65988 41.9081 0.0443344 39.2926 0.0443344 36.0564C0.0443344 32.8202 2.65988 30.2047 5.89606 30.2047H11.7478V36.0564ZM14.6737 36.0564C14.6737 32.8202 17.2892 30.2047 20.5254 30.2047C23.7616 30.2047 26.3771 32.8202 26.3771 36.0564V50.6857C26.3771 53.9219 23.7616 56.5374 20.5254 56.5374C17.2892 56.5374 14.6737 53.9219 14.6737 50.6857V36.0564Z'
              fill='#E01E5A'
            />
            <path
              d='M20.5254 12.5608C17.2892 12.5608 14.6737 9.94526 14.6737 6.70907C14.6737 3.47289 17.2892 0.857346 20.5254 0.857346C23.7616 0.857346 26.3771 3.47289 26.3771 6.70907V12.5608H20.5254ZM20.5254 15.531C23.7616 15.531 26.3771 18.1465 26.3771 21.3827C26.3771 24.6189 23.7616 27.2345 20.5254 27.2345H5.85173C2.61555 27.2345 0 24.6189 0 21.3827C0 18.1465 2.61555 15.531 5.85173 15.531H20.5254Z'
              fill='#36C5F0'
            />
            <path
              d='M43.9766 21.3827C43.9766 18.1465 46.5922 15.531 49.8284 15.531C53.0645 15.531 55.6801 18.1465 55.6801 21.3827C55.6801 24.6189 53.0645 27.2345 49.8284 27.2345H43.9766V21.3827ZM41.0508 21.3827C41.0508 24.6189 38.4352 27.2345 35.199 27.2345C31.9629 27.2345 29.3473 24.6189 29.3473 21.3827V6.70907C29.3473 3.47289 31.9629 0.857346 35.199 0.857346C38.4352 0.857346 41.0508 3.47289 41.0508 6.70907V21.3827Z'
              fill='#2EB67D'
            />
            <path
              d='M35.199 44.834C38.4352 44.834 41.0508 47.4495 41.0508 50.6857C41.0508 53.9219 38.4352 56.5374 35.199 56.5374C31.9629 56.5374 29.3473 53.9219 29.3473 50.6857V44.834H35.199ZM35.199 41.9081C31.9629 41.9081 29.3473 39.2926 29.3473 36.0564C29.3473 32.8202 31.9629 30.2047 35.199 30.2047H49.8727C53.1089 30.2047 55.7244 32.8202 55.7244 36.0564C55.7244 39.2926 53.1089 41.9081 49.8727 41.9081H35.199Z'
              fill='#ECB22E'
            />
          </svg>
          <svg
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.72443 15.7147V8.71469M8.72443 8.71469V1.71469M8.72443 8.71469H1.72443M8.72443 8.71469H15.7244'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <svg
            width='93'
            height='62'
            viewBox='0 0 93 62'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M71.7277 22.8677C70.3172 23.0659 69.1963 22.4015 69.3071 20.6833L70.0452 10.6868C70.1726 8.87823 70.9638 8.03314 72.2017 7.85918C74.4758 7.53956 74.4464 9.62793 74.3856 11.0749L74.3433 11.6092L74.4008 11.6012C74.9817 9.25915 75.9813 7.18121 77.8812 6.91419C78.9464 6.7645 79.5454 7.26741 79.6911 8.30374C79.95 10.1461 78.6901 10.9983 77.3366 11.394C75.9009 11.8307 74.7804 12.8395 74.5849 15.6263L74.2297 19.9915C74.0901 21.7137 73.2534 22.6533 71.7277 22.8677Z'
              fill='#FF4700'
            />
            <path
              d='M61.5684 24.4423C57.5382 25.0087 54.707 22.6178 54.2094 19.077C53.6146 14.8454 55.7304 10.0566 61.6029 9.23129C64.9422 8.76199 67.0354 10.2878 67.3388 12.4469C67.7838 15.6134 64.2308 17.9034 59.0334 18.311C59.4322 20.1043 60.5975 21.0854 62.2096 20.8588C64.8868 20.4826 64.8505 18.3447 66.3762 18.1303C66.8944 18.0575 67.2884 18.3544 67.3855 19.0453C67.608 20.6285 65.9152 23.8314 61.5684 24.4423ZM58.8896 16.4524C61.4643 15.9732 63.5019 14.8061 63.2511 13.0214C63.1418 12.2441 62.4973 11.8356 61.6913 11.9489C59.8201 12.2119 58.9114 14.1009 58.8896 16.4524Z'
              fill='#FF4700'
            />
            <path
              d='M39.5365 33.2632C37.982 33.4816 36.8859 32.7844 37.0008 31.095L38.1387 15.1712C38.2531 13.6873 38.9423 12.5337 40.727 12.2829C42.1952 12.0765 43.1594 12.8804 43.0325 14.4834L42.973 15.3137L43.0306 15.3056C43.6267 13.4899 44.9385 11.5442 47.6444 11.1639C50.2065 10.8038 51.925 12.5878 52.3493 15.816C52.9229 20.3148 51.1547 25.906 46.3761 26.5776C44.4762 26.8446 42.8008 25.9939 42.3403 24.1799L42.2828 24.188L41.836 30.8264C41.7464 32.2774 40.9759 33.0609 39.5365 33.2632ZM44.7054 23.6714C46.6053 23.4044 47.3967 20.6805 47.3174 17.6093C47.2469 16.0634 46.6942 15.0549 45.226 15.2613C43.2685 15.5364 42.603 18.9471 42.646 21.7592C42.7664 23.0339 43.5251 23.8373 44.7054 23.6714Z'
              fill='#FF4700'
            />
            <path
              d='M24.8417 29.6037C21.8191 30.0285 20.4959 27.9247 20.7594 24.1596L21.239 17.5459C21.3371 15.9469 22.2728 14.876 23.8561 14.6535C25.4106 14.4351 26.2309 15.2591 26.1327 16.8581L25.7 22.7607C25.5811 24.4213 26.0358 25.15 27.0433 25.0084C28.7417 24.7697 29.7171 22.519 29.8959 19.8226L30.1341 16.2958C30.2282 14.668 31.2831 13.6097 32.78 13.3994C34.3345 13.1809 35.1508 13.9762 35.0279 15.608L34.3307 25.6868C34.1831 27.3515 33.2848 28.2703 31.8166 28.4767C30.3197 28.687 29.2934 28.068 29.4082 26.3786L29.5311 24.7468L29.4736 24.7549C28.8532 26.3979 27.9794 29.1627 24.8417 29.6037Z'
              fill='#FF4700'
            />
            <path
              d='M11.3522 31.4996C6.7751 32.1429 3.26917 29.9643 2.94147 27.6325C2.75132 26.2796 3.57991 25.2824 4.9329 25.0923C6.05558 24.9345 6.72795 25.75 7.47003 26.6438C8.49048 28.0562 9.47141 28.7697 11.3426 28.5067C13.0122 28.2721 14.0014 27.1643 13.8031 25.7538C13.6373 24.5735 12.677 23.7984 10.6188 23.1483C6.95451 21.9313 4.56442 20.7995 4.12344 17.6617C3.60154 13.9482 6.25149 11.0806 11.3468 10.3645C15.4921 9.78189 18.4929 11.7086 18.7559 13.5797C18.9743 15.1342 18.1048 16.049 16.6943 16.2473C15.1973 16.4576 14.5466 15.1694 14.2066 14.6301C13.6782 13.7943 12.8245 13.151 11.27 13.3695C9.60041 13.6042 8.66071 14.6462 8.83872 15.9129C9.04505 17.381 10.2325 17.6838 12.4589 18.4864C15.4494 19.5045 18.1476 20.7399 18.5765 23.7913C19.1307 27.7351 16.4475 30.7835 11.3522 31.4996Z'
              fill='#FF4700'
            />
            <path
              d='M85.633 50.4155C81.7467 50.9616 80.9472 48.1972 81.2314 44.3704L81.6884 38.6405L80.796 38.766C80.249 38.8428 79.7111 38.5662 79.6059 37.8177C79.4724 36.8677 79.9263 36.1288 80.9339 35.9872L82.2005 35.8092L82.3198 33.943C82.3888 32.5536 83.0244 31.4368 84.694 31.2022C86.7379 30.9149 86.9987 31.9351 86.901 33.3285L86.7776 35.1659L88.9942 34.8544C89.5987 34.7694 90.0336 35.1486 90.1226 35.7819C90.2602 36.7607 89.8063 37.4997 88.7987 37.6413L86.5534 37.9568L86.0234 45.0473C85.9423 46.3503 86.1343 46.881 86.854 46.7799C87.4585 46.6949 87.62 46.1732 88.3109 46.0761C88.8579 45.9992 89.1817 46.4234 89.2545 46.9416C89.4811 48.5536 88.0798 50.0716 85.633 50.4155Z'
              fill='#FF4700'
            />
            <path
              d='M72.6254 52.2437C68.5952 52.8101 65.764 50.4193 65.2664 46.8785C64.6717 42.6468 66.7874 37.8581 72.6599 37.0328C75.9992 36.5634 78.0924 38.0893 78.3958 40.2483C78.8409 43.4149 75.2878 45.7049 70.0904 46.1124C70.4893 47.9058 71.6546 48.8869 73.2666 48.6603C75.9438 48.284 75.9076 46.1462 77.4333 45.9318C77.9514 45.859 78.3454 46.1558 78.4425 46.8467C78.665 48.43 76.9722 51.6328 72.6254 52.2437ZM69.9467 44.2539C72.5213 43.7746 74.5589 42.6076 74.3081 40.8228C74.1989 40.0456 73.5543 39.6371 72.7483 39.7504C70.8772 40.0134 69.9684 41.9024 69.9467 44.2539Z'
              fill='#FF4700'
            />
            <path
              d='M57.8096 54.3253C53.7795 54.8917 50.9482 52.5009 50.4506 48.9601C49.8559 44.7284 51.9716 39.9397 57.8442 39.1144C61.1834 38.6451 63.2766 40.1709 63.5801 42.3299C64.0251 45.4965 60.472 47.7865 55.2747 48.194C55.6735 49.9874 56.8388 50.9685 58.4509 50.7419C61.128 50.3657 61.0918 48.2278 62.6175 48.0134C63.1356 47.9406 63.5296 48.2375 63.6267 48.9283C63.8493 50.5116 62.1564 53.7144 57.8096 54.3253ZM55.1309 46.3355C57.7055 45.8562 59.7432 44.6892 59.4923 42.9044C59.3831 42.1272 58.7386 41.7187 57.9325 41.832C56.0614 42.095 55.1527 43.984 55.1309 46.3355Z'
              fill='#FF4700'
            />
            <path
              d='M32.3353 57.8768C28.9096 58.3582 27.3441 56.2005 27.6615 52.1929L28.1054 46.7878C28.2495 44.8888 28.6846 43.3892 30.6421 43.1141C32.3118 42.8795 33.0498 43.7445 32.8936 45.5571L32.4951 50.8677C32.3303 52.8284 32.4179 54.0783 33.9724 53.8599C35.5556 53.6373 36.3803 50.7327 36.556 47.5963L36.6344 45.6478C36.7669 43.4569 37.2262 42.1301 39.1549 41.859C40.8821 41.6163 41.5459 42.5798 41.4226 44.4171L41.1594 49.8555C41.0734 51.5409 41.1232 53.1484 42.5049 52.9542C44.0306 52.7398 45.427 49.7254 45.1012 46.5715C44.965 44.9761 44.4897 44.309 44.3562 43.359C44.1539 41.9197 44.9411 41.0458 46.2653 40.8597C47.8486 40.6372 48.4296 41.8472 48.6359 43.3153C49.1376 46.8849 47.0453 55.8094 41.1728 56.6348C38.7547 56.9746 37.5282 55.7673 37.0107 53.7558C36.1343 55.8751 34.6958 57.545 32.3353 57.8768Z'
              fill='#FF4700'
            />
            <path
              d='M18.2757 59.8817C13.6986 60.525 10.1927 58.3463 9.86497 56.0146C9.67482 54.6616 10.5034 53.6645 11.8564 53.4744C12.9791 53.3166 13.6514 54.1321 14.3935 55.0259C15.414 56.4383 16.3949 57.1518 18.2661 56.8888C19.9357 56.6541 20.9249 55.5464 20.7266 54.1358C20.5608 52.9556 19.6005 52.1805 17.5423 51.5304C13.878 50.3134 11.4879 49.1816 11.0469 46.0438C10.525 42.3303 13.175 39.4626 18.2703 38.7466C22.4156 38.164 25.4164 40.0907 25.6794 41.9618C25.8978 43.5163 25.0283 44.4311 23.6178 44.6293C22.1208 44.8397 21.4701 43.5515 21.1301 43.0121C20.6017 42.1764 19.748 41.5331 18.1935 41.7516C16.5239 41.9862 15.5842 43.0283 15.7622 44.2949C15.9686 45.7631 17.156 46.0659 19.3824 46.8685C22.3729 47.8866 25.0711 49.1219 25.5 52.1734C26.0542 56.1172 23.371 59.1656 18.2757 59.8817Z'
              fill='#FF4700'
            />
          </svg>
        </div>
        {(isLoading || data) && (
          <>
            <h1>Connecting Slack to SuperSweet...</h1>
            <Loader />
          </>
        )}
        {error?.error && !isLoading && (
          <div className='flex gap-4 flex-col'>
            <p className='text-red-500'>
              Oops! There was an error while connecting: {error?.error}
            </p>
            <div className='flex justify-center'>
              <Button
                label='Back to settings'
                variant='secondary'
                onClick={() => {
                  ROUTER.NAVIGATE(`/${organizationSlug}/settings/apps`);
                }}
              />
            </div>
          </div>
        )}
      </Card>
    </PageLayout>
  );
}
