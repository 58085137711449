import React, { useEffect } from 'react';

import { Header, PageLayout, EmptyState } from '../../ui';

import { useRequest, useRouter } from '../../hooks';
import { DailyActivity } from './components/DailyActivity/DailyActivity';

export function Activity() {
  const { data, request, isLoading } = useRequest();
  const { API } = useRouter();

  useEffect(() => {
    request({
      url: API.ACTIVITY,
    });
  }, []);

  return (
    <PageLayout>
      <Header>
        <h1>Activity</h1>
      </Header>
      {data?.map((activity) => {
        return <DailyActivity activity={activity} />;
      })}
      {!isLoading && data?.length === 0 && (
        <EmptyState>
          <h2>You don't have any activity yet</h2>
        </EmptyState>
      )}
    </PageLayout>
  );
}
