import React from 'react';
import clsx from 'clsx';

import { Header, PageLayout } from '../../ui';
import { PageCard } from './components/PageCard';

import { useRouter } from '../../hooks';

import styles from './Settings.module.css';

export function Settings() {
  const ROUTER = useRouter();

  return (
    <PageLayout>
      <Header>
        <h1>Settings</h1>
      </Header>
      <div className={clsx(styles.grid)}>
        <ROUTER.LINK to={ROUTER.PATH.ACCOUNT_INDEX}>
          <PageCard
            pageName='Account'
            icon={
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.9946 18.1467C17.893 16.3926 16.3672 15 14.5 15H7.5C5.63276 15 4.10703 16.3926 4.00539 18.1467M17.9946 18.1467C19.8491 16.3314 21 13.8001 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 13.8001 2.15087 16.3314 4.00539 18.1467M17.9946 18.1467C16.1913 19.9119 13.7227 21 11 21C8.27726 21 5.80867 19.9119 4.00539 18.1467M14 9C14 10.6569 12.6569 12 11 12C9.3431 12 8 10.6569 8 9C8 7.34315 9.3431 6 11 6C12.6569 6 14 7.34315 14 9Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
          />
        </ROUTER.LINK>
        <ROUTER.LINK to={ROUTER.PATH.PEOPLE}>
          <PageCard
            pageName='People'
            icon={
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.32836 6.19123C4.89484 6.19123 3.73274 5.02914 3.73274 3.59562C3.73274 2.1621 4.89484 1 6.32836 1C7.76188 1 8.92398 2.1621 8.92398 3.59562C8.92398 5.02914 7.76188 6.19123 6.32836 6.19123ZM6.32836 6.19123C8.25966 6.19123 9.967 7.15 11.0005 8.6175C12.0339 7.15 13.7413 6.19123 15.6726 6.19123M6.32836 6.19123C4.39706 6.19123 2.68969 7.15 1.65625 8.6175M15.6726 6.19123C14.2391 6.19123 13.077 5.02914 13.077 3.59562C13.077 2.1621 14.2391 1 15.6726 1C17.1061 1 18.2682 2.1621 18.2682 3.59562C18.2682 5.02914 17.1061 6.19123 15.6726 6.19123ZM15.6726 6.19123C17.6039 6.19123 19.3113 7.15 20.3447 8.6175M6.32836 16.5737C4.89484 16.5737 3.73274 15.4116 3.73274 13.9781C3.73274 12.5446 4.89484 11.3825 6.32836 11.3825C7.76188 11.3825 8.92398 12.5446 8.92398 13.9781C8.92398 15.4116 7.76188 16.5737 6.32836 16.5737ZM6.32836 16.5737C8.25966 16.5737 9.967 17.5325 11.0005 19C12.0339 17.5325 13.7413 16.5737 15.6726 16.5737M6.32836 16.5737C4.39706 16.5737 2.68969 17.5325 1.65625 19M15.6726 16.5737C14.2391 16.5737 13.077 15.4116 13.077 13.9781C13.077 12.5446 14.2391 11.3825 15.6726 11.3825C17.1061 11.3825 18.2682 12.5446 18.2682 13.9781C18.2682 15.4116 17.1061 16.5737 15.6726 16.5737ZM15.6726 16.5737C17.6039 16.5737 19.3113 17.5325 20.3447 19'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
          />
        </ROUTER.LINK>
        <ROUTER.LINK to={ROUTER.PATH.BILLING}>
          <PageCard
            pageName='Billing'
            icon={
              <svg
                width='22'
                height='18'
                viewBox='0 0 22 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 6H21M5 10H8M14.6 17H7.4C5.15979 17 4.03969 17 3.18404 16.564C2.43139 16.1805 1.81947 15.5686 1.43597 14.816C1 13.9603 1 12.8402 1 10.6V7.4C1 5.15979 1 4.03968 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03969 1 5.15979 1 7.4 1H14.6C16.8402 1 17.9603 1 18.816 1.43597C19.5686 1.81947 20.1805 2.43139 20.564 3.18404C21 4.03968 21 5.15979 21 7.4V10.6C21 12.8402 21 13.9603 20.564 14.816C20.1805 15.5686 19.5686 16.1805 18.816 16.564C17.9603 17 16.8402 17 14.6 17Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
          />
        </ROUTER.LINK>
        <ROUTER.LINK to={ROUTER.PATH.APPS}>
          <PageCard
            pageName='Apps'
            icon={
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 4.2C1 3.0799 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.0799 1 4.2 1H4.8C5.9201 1 6.48016 1 6.90798 1.21799C7.28431 1.40973 7.59027 1.71569 7.78201 2.09202C8 2.51984 8 3.0799 8 4.2V4.8C8 5.9201 8 6.48016 7.78201 6.90798C7.59027 7.28431 7.28431 7.59027 6.90798 7.78201C6.48016 8 5.9201 8 4.8 8H4.2C3.0799 8 2.51984 8 2.09202 7.78201C1.71569 7.59027 1.40973 7.28431 1.21799 6.90798C1 6.48016 1 5.9201 1 4.8V4.2Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1 15.2C1 14.0799 1 13.5198 1.21799 13.092C1.40973 12.7157 1.71569 12.4097 2.09202 12.218C2.51984 12 3.0799 12 4.2 12H4.8C5.9201 12 6.48016 12 6.90798 12.218C7.28431 12.4097 7.59027 12.7157 7.78201 13.092C8 13.5198 8 14.0799 8 15.2V15.8C8 16.9201 8 17.4802 7.78201 17.908C7.59027 18.2843 7.28431 18.5903 6.90798 18.782C6.48016 19 5.9201 19 4.8 19H4.2C3.0799 19 2.51984 19 2.09202 18.782C1.71569 18.5903 1.40973 18.2843 1.21799 17.908C1 17.4802 1 16.9201 1 15.8V15.2Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12 4.2C12 3.0799 12 2.51984 12.218 2.09202C12.4097 1.71569 12.7157 1.40973 13.092 1.21799C13.5198 1 14.0799 1 15.2 1H15.8C16.9201 1 17.4802 1 17.908 1.21799C18.2843 1.40973 18.5903 1.71569 18.782 2.09202C19 2.51984 19 3.0799 19 4.2V4.8C19 5.9201 19 6.48016 18.782 6.90798C18.5903 7.28431 18.2843 7.59027 17.908 7.78201C17.4802 8 16.9201 8 15.8 8H15.2C14.0799 8 13.5198 8 13.092 7.78201C12.7157 7.59027 12.4097 7.28431 12.218 6.90798C12 6.48016 12 5.9201 12 4.8V4.2Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12 15.2C12 14.0799 12 13.5198 12.218 13.092C12.4097 12.7157 12.7157 12.4097 13.092 12.218C13.5198 12 14.0799 12 15.2 12H15.8C16.9201 12 17.4802 12 17.908 12.218C18.2843 12.4097 18.5903 12.7157 18.782 13.092C19 13.5198 19 14.0799 19 15.2V15.8C19 16.9201 19 17.4802 18.782 17.908C18.5903 18.2843 18.2843 18.5903 17.908 18.782C17.4802 19 16.9201 19 15.8 19H15.2C14.0799 19 13.5198 19 13.092 18.782C12.7157 18.5903 12.4097 18.2843 12.218 17.908C12 17.4802 12 16.9201 12 15.8V15.2Z'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
          />
        </ROUTER.LINK>
      </div>
    </PageLayout>
  );
}
