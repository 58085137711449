import { useParams, useNavigate, Link } from 'react-router-dom';

export function useRouter() {
  const {
    checklistUniqueId,
    eventUniqueId,
    fileUniqueId,
    organizationSlug,
    postCategoryUniqueId,
    postUniqueId,
    projectUniqueId,
    taskUniqueId,
  } = useParams();

  const navigate = useNavigate();

  return {
    API: {
      // ACCOUNT
      ACCOUNT: '/api/v1/account',
      ACCOUNT_INDEX: `/api/v1/account`,

      // ACTIVITY
      ACTIVITY: '/api/v1/activity',

      // BILLING (STRIPE)
      BILLING_STATUS: `/api/v1/billing/status?organization_slug=${organizationSlug}`,
      BILLING_CREATE: `/api/v1/billing/create?organization_slug=${organizationSlug}`,
      BILLING_MANAGE: `/api/v1/billing/manage?organization_slug=${organizationSlug}`,

      // ORGANIZATIONS
      ORGANIZATIONS_SHOW: `/api/v1/organizations?organization_slug=${organizationSlug}`,

      // ORGANIZATION USERS
      ORGANIZATION_USERS_BASE: `/api/v1/organization-users?organization_slug=${organizationSlug}`,

      // ORGANIZATION PROJECT USERS
      ORGANIZATION_PROJECT_USERS_SHOW: `/api/v1/organization-project-users/${projectUniqueId}`,

      // PROJECT USERS
      PROJECT_USERS_CREATE: `/api/v1/project-users`,
      PROJECT_USERS_SHOW: `/api/v1/project-users/${projectUniqueId}`,
      PROJECT_USERS_DESTROY: `/api/v1/project-users`,

      // PROJECTS
      PROJECTS_INDEX: `/api/v1/projects?organization_slug=${organizationSlug}`,
      PROJECTS_DELETE: `/api/v1/projects/${projectUniqueId}`,
      PROJECTS_SHOW: `/api/v1/projects/${projectUniqueId}`,

      // CHECKLISTS
      CHECKLISTS_INDEX: `/api/v1/checklists?project_unique_id=${projectUniqueId}`,
      CHECKLISTS_CREATE: `/api/v1/checklists?project_unique_id=${projectUniqueId}`,

      // EVENTS
      EVENTS_INDEX: `/api/v1/events?project_unique_id=${projectUniqueId}`,
      EVENTS_SHOW: `/api/v1/events/${eventUniqueId}`,
      EVENTS_UPDATE: `/api/v1/events/${eventUniqueId}`,
      EVENTS_DELETE: `/api/v1/events/${eventUniqueId}`,

      // COMMENTS
      EVENT_COMMENTS_SHOW: `/api/v1/comments?event_unique_id=${eventUniqueId}`,
      EVENT_COMMENTS_CREATE: `/api/v1/comments?event_unique_id=${eventUniqueId}`,
      TASK_COMMENTS_SHOW: `/api/v1/comments?task_unique_id=${taskUniqueId}`,
      TASK_COMMENTS_CREATE: `/api/v1/comments?task_unique_id=${taskUniqueId}`,
      POST_COMMENTS_SHOW: `/api/v1/comments?post_unique_id=${postUniqueId}`,
      POST_COMMENTS_CREATE: `/api/v1/comments?post_unique_id=${postUniqueId}`,
      FILE_COMMENTS_SHOW: `/api/v1/comments?file_unique_id=${fileUniqueId}`,
      FILE_COMMENTS_CREATE: `/api/v1/comments?file_unique_id=${fileUniqueId}`,

      // TASKS
      TASKS_BASE: `/api/v1/tasks`,
      TASKS_SHOW: `/api/v1/tasks/${taskUniqueId}`,
      TASKS_UPDATE: `/api/v1/tasks/${taskUniqueId}`,
      // TASK_STATUS intentionally leaves off the task_unique_id so that it can
      // be appended to the URL
      TASK_STATUS: `/api/v1/task-status`,

      // POST CATEGORIES
      POST_CATEGORIES_INDEX: `/api/v1/post-categories/${projectUniqueId}`,
      POST_CATEGORIES_CREATE: `/api/v1/post-categories/${projectUniqueId}`,
      POST_CATEGORIES_UPDATE: `/api/v1/post-categories`,
      // CATEGORY POSTS
      // All the posts for a category
      CATEGORY_POSTS_INDEX: `/api/v1/category-posts/${postCategoryUniqueId}`,
      CATEGORY_POSTS_SHOW: `/api/v1/category-posts/${postCategoryUniqueId}`,

      // POSTS
      POSTS_CREATE: `/api/v1/posts?project_unique_id=${projectUniqueId}&post_category_unique_id=${postCategoryUniqueId}`,
      POSTS_SHOW: `/api/v1/posts/${postUniqueId}`,
      POSTS_DELETE: `/api/v1/posts/${postUniqueId}`,
      POSTS_UPDATE: `/api/v1/posts/${postUniqueId}`,

      // FILES
      FILES_CREATE: `/api/v1/files?project_unique_id=${projectUniqueId}`,
      FILES_INDEX: `/api/v1/files?project_unique_id=${projectUniqueId}`,
      FILES_SHOW: `/api/v1/files/${fileUniqueId}`,
      FILES_UPDATE: `/api/v1/files/${fileUniqueId}`,
      FILES_DELETE: `/api/v1/files/${fileUniqueId}`,

      // INVITE USER
      INVITE_USER_BASE: `/api/v1/invite-user`,
    },
    PATH: {
      PROJECTS_BASE: `/${organizationSlug}/projects`,
      PROJECTS_INDEX: `/${organizationSlug}/projects/`,
      PROJECTS_SHOW: `/${organizationSlug}/projects/${projectUniqueId}`,
      PROJECTS_SETTINGS: `/${organizationSlug}/projects/${projectUniqueId}/settings`,
      CHECKLISTS_INDEX: `/${organizationSlug}/projects/${projectUniqueId}/checklists`,
      // CALENDAR & EVENTS
      CALENDAR_INDEX: `/${organizationSlug}/projects/${projectUniqueId}/calendar`,
      EVENT_BASE: `/${organizationSlug}/projects/${projectUniqueId}/events`,
      EVENT_EDIT: `/${organizationSlug}/projects/${projectUniqueId}/events/${eventUniqueId}/edit`,
      EVENT_SHOW: `/${organizationSlug}/projects/${projectUniqueId}/events/${eventUniqueId}/`,
      FILES_INDEX: `/${organizationSlug}/projects/${projectUniqueId}/files`,
      // FILES_SHOW intentionally leaves off the file_unique_id so that it can be
      // appended to the URL
      FILES_SHOW: `/${organizationSlug}/projects/${projectUniqueId}/files`,
      FILES_NEW: `/${organizationSlug}/projects/${projectUniqueId}/files/new`,
      POSTS_CATEGORIES_INDEX: `/${organizationSlug}/projects/${projectUniqueId}/posts`,
      POSTS_CATEGORIES_SHOW: `/${organizationSlug}/projects/${projectUniqueId}/posts/categories`,
      POSTS_CATEGORIES_SHOW_POSTS: `/${organizationSlug}/projects/${projectUniqueId}/posts/categories/${postCategoryUniqueId}`,
      // TASK
      TASK_BASE: `/${organizationSlug}/projects/${projectUniqueId}/checklists`,
      TASK_SHOW: `/${organizationSlug}/projects/${projectUniqueId}/checklists/${checklistUniqueId}/tasks/${taskUniqueId}/`,

      // POSTS
      POSTS_NEW: `/${organizationSlug}/projects/${projectUniqueId}/posts/categories/${postCategoryUniqueId}/new`,
      // ACTIVITY
      ACTIVITY: `/${organizationSlug}/activity`,
      // POSTS_SHOW intentionally leaves off the post_unique_id so that it can
      // be appended to the URL
      POSTS_SHOW: `/${organizationSlug}/projects/${projectUniqueId}/posts`,
      // PEOPLE
      PEOPLE: `/${organizationSlug}/people`,
      // ACCOUNT
      ACCOUNT_INDEX: `/${organizationSlug}/settings/account`,
      // BILLING
      BILLING: `/${organizationSlug}/settings/billing`,
      APPS: `/${organizationSlug}/settings/apps`,
    },
    PARAMS: {
      EVENT_UNIQUE_ID: eventUniqueId,
      FILE_UNIQUE_ID: fileUniqueId,
      ORGANIZATION_SLUG: organizationSlug,
      POST_CATEGORY_UNIQUE_ID: postCategoryUniqueId,
      POST_UNIQUE_ID: postUniqueId,
      PROJECT_UNIQUE_ID: projectUniqueId,
      TASK_UNIQUE_ID: taskUniqueId,
    },
    REDIRECT_URIS: {
      SLACK: {
        OAUTH: {
          DEVELOPMENT: 'https://3f0633271384.ngrok.app/apps/oauth/slack',
          STAGING:
            'https://supersweet-staging-84429ecf7d12.herokuapp.com/apps/oauth/slack',
          PRODUCTION: 'https://www.supersweetness.com/apps/oauth/slack',
        },
      },
    },
    NAVIGATE: navigate,
    LINK: Link,
    USE_PARAMS: useParams,
    IDS: {
      ORGANIZATION_SLUG: organizationSlug,
      PROJECT_UNIQUE_ID: projectUniqueId,
    },
  };
}
