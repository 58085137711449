import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  PageLayout,
  DropdownMenu,
  Header,
  TrashIcon,
  PeopleIcon,
  Card,
} from '../../../ui';
import { DeleteProjectModal } from './components/DeleteProjectModal';
import { AddPeopleToProjectDialog } from './components/AddPeopleToProjectDialog';
import { useRequest, useRouter } from '../../../hooks';

import styles from './ProjectsShow.module.css';

export function ProjectsShow() {
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showPeopleModal, setShowPeopleModal] = useState(false);

  const ROUTER = useRouter();
  const { request, data } = useRequest();

  useEffect(() => {
    request({
      url: ROUTER.API.PROJECTS_SHOW,
    });
  }, []);

  const items = [
    {
      text: 'Project settings',
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.1741 21H8.4C6.15979 21 5.03968 21 4.18404 20.564C3.43139 20.1805 2.81947 19.5686 2.43597 18.816C2 17.9603 2 16.8402 2 14.6V9.4C2 7.15979 2 6.03968 2.43597 5.18404C2.81947 4.43139 3.43139 3.81947 4.18404 3.43597C5.03968 3 6.15979 3 8.4 3H8.71556C9.18517 3 9.41997 3 9.6331 3.06473C9.82179 3.12203 9.99732 3.21597 10.1497 3.34118C10.3217 3.48262 10.452 3.67798 10.7125 4.06872L11.2875 4.93128C11.548 5.32202 11.6783 5.51739 11.8503 5.65882C12.0027 5.78403 12.1782 5.87797 12.3669 5.93527C12.58 6 12.8148 6 13.2844 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C21.9256 8.89365 21.9873 9.78516 21.9978 11.3466M18 18H18.01M18 14L19.1787 15.1545L20.8284 15.1716L20.8455 16.8213L22 18L20.8455 19.1787L20.8284 20.8284L19.1787 20.8455L18 22L16.8213 20.8455L15.1716 20.8284L15.1545 19.1787L14 18L15.1545 16.8213L15.1716 15.1716L16.8213 15.1545L18 14Z'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      onClick: () => {
        ROUTER.NAVIGATE(ROUTER.PATH.PROJECTS_SETTINGS);
      },
    },
    {
      text: 'Add / remove people',
      icon: <PeopleIcon />,
      onClick: () => setShowPeopleModal(true),
    },
    {
      text: 'Delete project',
      icon: <TrashIcon />,
      onClick: () => setShowDeleteProjectModal(true),
      danger: true,
    },
  ];

  return (
    <PageLayout>
      <Header>
        <h1>
          <span className={clsx(styles.emoji)}>{data?.emoji}</span> {data?.name}
        </h1>
        <DropdownMenu items={items} />
      </Header>
      <div className={clsx(styles['grid-wrapper'])}>
        <ROUTER.LINK to={ROUTER.PATH.CHECKLISTS_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='47'
              height='47'
              viewBox='0 0 47 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14 23.6925L19.9186 31L20.4439 30.0197C23.2599 24.7636 27.1572 20.2586 31.8418 16.8441L33 16M17 45H30C38.2843 45 45 38.2843 45 30V17C45 8.71573 38.2843 2 30 2H17C8.71573 2 2 8.71573 2 17V30C2 38.2843 8.71573 45 17 45Z'
                stroke='#2ECF8F'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <h2>Checklists</h2>
          </Card>
        </ROUTER.LINK>

        <ROUTER.LINK to={ROUTER.PATH.CALENDAR_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='41'
              height='45'
              viewBox='0 0 41 45'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M38.7927 18.4168C38.875 20.0253 38.875 22.0007 38.875 24.5418C38.875 30.2496 38.875 33.1035 37.9425 35.3547C36.6992 38.3563 34.3145 40.7411 31.3129 41.9844C29.0617 42.9168 26.2078 42.9168 20.5 42.9168C14.7922 42.9168 11.9383 42.9168 9.68713 41.9844C6.68553 40.7411 4.30078 38.3563 3.05748 35.3547C2.125 33.1035 2.125 30.2496 2.125 24.5418C2.125 22.0007 2.125 20.0253 2.20728 18.4168M38.7927 18.4168C38.6902 16.4124 38.4599 14.9779 37.9425 13.729C36.6992 10.7274 34.3145 8.34261 31.3129 7.09931C30.534 6.77669 29.683 6.5657 28.6667 6.4277M38.7927 18.4168L2.20728 18.4168M2.20728 18.4168C2.30982 16.4124 2.54014 14.9779 3.05748 13.729C4.30078 10.7274 6.68553 8.34261 9.68713 7.09931C10.466 6.77669 11.317 6.5657 12.3333 6.4277M12.3333 2.0835V6.4277M12.3333 6.4277V10.2502M12.3333 6.4277C14.2547 6.16683 16.767 6.16683 20.5 6.16683C24.233 6.16683 26.7453 6.16683 28.6667 6.4277M28.6667 2.0835V6.4277M28.6667 6.4277V10.2502M12.3538 26.5835H12.3333M12.3538 34.7502H12.3333M20.5204 26.5835H20.5M20.5204 34.7502H20.5M28.6871 26.5835H28.6667M28.6871 34.7502H28.6667'
                stroke='#FE074D'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <h2>Calendar</h2>
          </Card>
        </ROUTER.LINK>

        <ROUTER.LINK to={ROUTER.PATH.POSTS_CATEGORIES_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='43'
              height='42'
              viewBox='0 0 43 42'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12.4163 16.6667H29.7497M12.4163 25.3333H21.083M40.583 21C40.583 31.7696 31.8527 40.5 21.083 40.5C20.1477 40.5 19.2277 40.4341 18.3277 40.3067C15.7196 39.938 14.4156 39.7536 14.0723 39.7271C13.5146 39.684 13.7146 39.691 13.1553 39.6953C12.811 39.6979 12.4186 39.7261 11.6348 39.782L6.96928 40.1152C5.11156 40.248 4.18262 40.3143 3.48799 39.985C2.8782 39.696 2.38712 39.2048 2.09802 38.5951C1.76869 37.9004 1.83503 36.9716 1.96774 35.1137L2.301 30.4482C2.35701 29.6641 2.385 29.2721 2.38769 28.9276C2.39202 28.3684 2.39895 28.5684 2.35594 28.0107C2.32947 27.6675 2.14504 26.3634 1.77617 23.7554C1.64885 22.8553 1.58301 21.9354 1.58301 21C1.58301 10.2305 10.3135 1.5 21.083 1.5C31.8527 1.5 40.583 10.2305 40.583 21Z'
                stroke='#66BAEF'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <h2>Posts</h2>
          </Card>
        </ROUTER.LINK>

        <ROUTER.LINK to={ROUTER.PATH.FILES_INDEX}>
          <Card className={clsx(styles.card, 'flex flex-col')}>
            <svg
              className='mb-4'
              width='28'
              height='44'
              viewBox='0 0 28 44'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M26 16V30C26 36.6274 20.6274 42 14 42C7.37258 42 2 36.6274 2 30V10C2 5.58172 5.58172 2 10 2C14.4182 2 18 5.58172 18 10V30C18 32.2092 16.2092 34 14 34C11.7908 34 10 32.2092 10 30V12'
                stroke='#FFA63F'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <h2>Files</h2>
          </Card>
        </ROUTER.LINK>
      </div>
      <DeleteProjectModal
        open={showDeleteProjectModal}
        onOpenChange={() => setShowDeleteProjectModal(false)}
      />
      <AddPeopleToProjectDialog
        open={showPeopleModal}
        onOpenChange={() => setShowPeopleModal(false)}
      />
    </PageLayout>
  );
}
