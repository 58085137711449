import React, { useEffect } from 'react';
import clsx from 'clsx';

import {
  Button,
  Card,
  CardRow,
  Header,
  PageLayout,
  SlackLogo,
} from '../../../ui';

import { useRouter, useRequest, useAccountContext } from '../../../hooks';
import styles from './AppsIndex.module.css';

export function AppsIndex() {
  const clientId = '7939002951827.7936288005861';

  const { request, data: organizationData } = useRequest();
  const ROUTER = useRouter();

  const account = useAccountContext();

  useEffect(() => {
    request({
      url: `/api/v1/organizations/${ROUTER.PARAMS.ORGANIZATION_SLUG}`,
    });
  }, []);

  const getSlackRedirectUri = () => {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('ngrok')
    ) {
      return ROUTER.REDIRECT_URIS.SLACK.OAUTH.DEVELOPMENT;
    }

    if (window.location.href.includes('herokuapp')) {
      return ROUTER.REDIRECT_URIS.SLACK.OAUTH.STAGING;
    }

    if (window.location.href.includes('supersweetness.com')) {
      return ROUTER.REDIRECT_URIS.SLACK.OAUTH.PRODUCTION;
    }
  };

  return (
    <PageLayout>
      <Header>
        <h1>Apps</h1>
      </Header>
      <p className='mb-8'>Connect to tools you already use with SuperSweet</p>
      <Card>
        <CardRow className='flex items-center justify-between'>
          <div className='flex items-center'>
            <SlackLogo className={clsx(styles.logo, 'mr-4')} />
            <h2>Slack</h2>
          </div>
          {account?.default_organization?.integrations?.slack_connected ? (
            <div className='flex items-center px-8'>
              <div className={clsx(styles['status-indicator'], 'mr-2')} />
              Connected
            </div>
          ) : (
            <Button
              variant='secondary'
              label='Connect'
              onClick={() => {
                window.location.assign(
                  `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=channels:read,chat:write,reminders:write&state=${ROUTER.PARAMS.ORGANIZATION_SLUG}&redirect_uri=${getSlackRedirectUri()}`
                );
              }}
            />
          )}
        </CardRow>
        <CardRow>
          <p className='mt-2'>
            To begin receiving messages in this channel, invite SuperSweet by
            typing: <strong>/invite @SuperSweet</strong> in Slack.
          </p>
        </CardRow>
      </Card>
    </PageLayout>
  );
}
