import React from 'react';

import { Header, PageLayout, Card, CardRow } from '../../ui';

export function Help() {
  return (
    <PageLayout>
      <Header>
        <h1>Help</h1>
      </Header>
      <Card className='mb-8'>
        <CardRow className='flex items-center'>
          <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='mr-2'
          >
            <path
              d='M22.3032 7.76159L16.795 11.2668C15.2385 12.2573 14.4602 12.7526 13.6238 12.9455C12.8843 13.1161 12.1157 13.1161 11.3762 12.9455C10.5398 12.7526 9.76153 12.2573 8.20499 11.2668L2.69678 7.76159M22.3032 7.76159C22.5 8.72189 22.5 10.006 22.5 12C22.5 14.8003 22.5 16.2004 21.955 17.27C21.4757 18.2108 20.7108 18.9757 19.77 19.455C18.7004 20 17.3003 20 14.5 20H10.5C7.69974 20 6.29961 20 5.23005 19.455C4.28924 18.9757 3.52433 18.2108 3.04497 17.27C2.5 16.2004 2.5 14.8003 2.5 12C2.5 10.006 2.5 8.72189 2.69678 7.76159M22.3032 7.76159C22.2237 7.37332 22.1119 7.03798 21.955 6.73005C21.4757 5.78924 20.7108 5.02433 19.77 4.54497C18.7004 4 17.3003 4 14.5 4H10.5C7.69974 4 6.29961 4 5.23005 4.54497C4.28924 5.02433 3.52433 5.78924 3.04497 6.73005C2.88807 7.03798 2.77634 7.37332 2.69678 7.76159'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p>
            <strong>E-mail Support</strong>
          </p>
        </CardRow>
        <CardRow>
          <p>
            We're always happy to help answer questions. You can email us at{' '}
            <a href='mailto:support@supersweetness.com'>
              <strong>support@supersweetness.com</strong>
            </a>
          </p>
        </CardRow>
      </Card>

      <Card>
        <CardRow className='flex items-center'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='mr-2'
          >
            <path
              d='M10.3789 21H13.621M12 2V1M19 4.70711L19.7071 4M4.70711 4.70711L4 4M22 11H21M3 11H2M6.3127 10.468C6.3127 7.44492 8.85908 4.99427 12.0002 4.99427C15.1413 4.99427 17.6877 7.44492 17.6877 10.468C17.6877 12.125 16.9226 13.6102 15.7138 14.6139C15.2032 15.0379 14.7642 15.5643 14.5974 16.2066L14.37 17.0819C14.2304 17.6192 13.7455 17.9943 13.1904 17.9943H10.81C10.2549 17.9943 9.76995 17.6192 9.63039 17.0819L9.40302 16.2066C9.23616 15.5643 8.7972 15.0379 8.28662 14.6139C7.07782 13.6102 6.3127 12.125 6.3127 10.468Z'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p>
            <strong>Feature Requests</strong>
          </p>
        </CardRow>
        <CardRow>
          <p>
            Have an idea for a new or existing feature? We'd love to hear it!
            Visit{' '}
            <a
              href='https://supersweet.canny.io/feature-requests'
              target='_blank'
              rel='noopener noreferrer'
            >
              <strong>this link</strong>
            </a>{' '}
            to let us know.
          </p>
        </CardRow>
      </Card>
    </PageLayout>
  );
}
