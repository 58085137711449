import React from 'react';
import clsx from 'clsx';

import styles from './Link.module.css';

export function Link({ href, className, target, children }) {
  const formatUrl = (url) => {
    return url?.includes('http') ? url : `https://${url}`;
  };

  return (
    <a
      href={formatUrl(href)}
      className={clsx(styles.base, className)}
      target={target}
    >
      {children}
    </a>
  );
}
