import React, { useState, useEffect } from 'react';

import { ProjectContext } from './ProjectContext';
import { useRequest } from '../../hooks';
import { useRouter } from '../../hooks';
import { excludeRequests } from '../utils/excludeRequests';

export function ProjectProvider({ children }) {
  const [project, setProject] = useState(null);
  const { request, data, isLoading } = useRequest();
  const ROUTER = useRouter();

  const fetchProject = () => {
    if (ROUTER.IDS.PROJECT_UNIQUE_ID) {
      request({
        url: ROUTER.API.PROJECTS_SHOW,
      });
    }
  };

  useEffect(() => {
    if (excludeRequests()) {
      return;
    }
    fetchProject();
  }, [ROUTER.PARAMS.PROJECT_UNIQUE_ID]);

  if (data && !isLoading && data !== project) {
    setProject(data);
  }

  return (
    <ProjectContext.Provider value={{ project, refetchProject: fetchProject }}>
      {children}
    </ProjectContext.Provider>
  );
}
