import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { routes } from '../src/router/routes';
import { sentry } from '../src/init/sentry';

const rootNode = document.getElementById('root');

sentry.init();

if (rootNode) {
  createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <RouterProvider router={routes} />
    </React.StrictMode>
  );
}
