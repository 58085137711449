import React from 'react';

import { AccountContext } from '../contexts/account';

export function useAccountContext() {
  const account = React.useContext(AccountContext);

  if (account === undefined) {
    throw new Error('useAccountContext must be used within an AccountContext');
  }

  return account;
}
